import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {PATH_LOGIN} from "../../App";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";
import Link from "@material-ui/core/Link/Link";

export default function ConfirmEmail(props){
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [successful, setSuccessful] = useState(null);

    const search = useLocation().search;
    const short = new URLSearchParams(search).get("short");
    // console.log('short=');
    // console.log(short);

    let defaultErrorMsg = 'Unable to confirm email. Please make sure you use the link you obtained from our email. ';
    useEffect(()=>{
        if(short){
            axios.get(`${process.env.REACT_APP_SPRING_BOOT_URL}/auth/confirm-email`, {
                params: {
                    short: short
                }
            })
                .then(res => {
                    console.log('res.data:');
                    console.log(res.data);
                    const successful = res.data.successful;
                    if(successful){
                        setSuccessful(()=>true);
                        setEmail(()=>res.data.email);
                        setMessage(()=>'Successfully confirmed your email: '+res.data.email +' . You may now login with your email and password');
                    }
                    else{ // error
                        setSuccessful(()=>false);
                        setMessage(()=>res.data.errorMsg);
                    }
                })
                .catch(error => {

                    console.log("error=");
                    console.log(error);

                    let errorMsg = error;
                    if (error.response) {
                        console.log(error.response.data);
                        if(error.response.data.error){
                            errorMsg = error.response.data.error;
                        }
                        if(error.response.data.message){
                            errorMsg = error.response.data.message;
                        }
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                    else if(error.message){
                        errorMsg = error.message;
                    }
                    setMessage(()=>defaultErrorMsg+errorMsg);
                }).finally(() => {

                }
            );
        }
    },[short, defaultErrorMsg]);

    if(!short){
        setMessage(()=>defaultErrorMsg);
    }

    let linkAddr = email? PATH_LOGIN+'?email='+email: PATH_LOGIN;

    let LoginLink = successful===null? <></>:
        <Typography variant="body2" color="textPrimary" align="center"><Link href={linkAddr}>Click here to login</Link></Typography>;
    return(
        <div>
            <Typography variant="h4" color="textPrimary" align="center">Email Confirmation</Typography>
            <Typography variant="body2" color="textPrimary" align="center">{message}</Typography>
            {LoginLink}
        </div>
    )
}
