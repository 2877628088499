import Nav from "../nav/Nav";
import React from "react";
import Me from "../nav/Me";
import Typography from "@material-ui/core/Typography/Typography";

export default function MyContacts(props){
    let myEmail = 'hafalquran.azifu@gmail.com';
    let emailTo = 'mailto:'+myEmail;
    return (
        <div>
            <Nav/>
            <Me/>
            <Typography variant="body2" color="textPrimary" align="center">
                Please send your feedback to <a href={emailTo}>{myEmail}</a>. Thanks.
            </Typography>
        </div>
    );
}
