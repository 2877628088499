import {PATH_LOGIN} from '../../App.tsx'
import {useEffect, useState} from "react";
import {Redirect} from "react-router";
import React from "react";
import {INIT, VALID, INVALID, TIMEOUT, useCheckTokenService} from "../../hooks/CheckTokenService";
import axios from "axios";
import {isAvailable} from "../../utils/Utils";
import Nav from "../nav/Nav";
import DataTable from "./DataTable";
import './Dashboard.css';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Container from "@material-ui/core/Container/Container";
import Me from "../nav/Me";

export default function Dashboard(props) {
    const [suratProgressList, setSuratProgressList] = useState();
    const [murajaahExpiryDays, setMurajaahExpiryDays] = useState();
    const [valid, setValid, validRef, cookies, onLogout, checkTimeout] = useCheckTokenService();

    // auto-logout if after xxx seconds, the validRef.current still stuck at INIT value, means cookie is wrong and not updated
    useEffect(()=>{
        let timeoutMillis = `${process.env.REACT_APP_CHECK_TIMEOUT_MILLIS}`;
        checkTimeout(validRef, setValid, onLogout, timeoutMillis);
    },[validRef, setValid, onLogout, checkTimeout]);


    useEffect(() => {
        let email = cookies.email;
        let token = cookies.token;
        console.log('valid='+valid +'. email:'+email+'. token='+token);

        // update the validRef to point to new value of valid (e.g. to detect it changed from INIT to VALID)
        // validRef will be used in checkTimeout.
        // checkTimeout is not able to simply use valid as the value of valid in checkTimeout won't be updated. Hence, the validRef.
        validRef.current = valid;

        if (valid === INIT && token && email) {

            axios.post(`${process.env.REACT_APP_SPRING_BOOT_URL}/dashboard/searchDashboardData`, email, {
                headers: {
                    'Authorization': 'BEARER ' + token,
                    'Content-Type': 'text/plain',
                },
                data: {
                    email,
                },
            })
                .then(res => {
                    const incomingList = res.data.suratProgressDtoList;
                    setSuratProgressList(()=> incomingList);
                    let murajaahExpDateIncoming = res.data.userDto.murajaahExpiryThresholdDays;
                    // console.log('murajaahExpDateIncoming=');
                    // console.log(murajaahExpDateIncoming);
                    setMurajaahExpiryDays(()=>murajaahExpDateIncoming);
                    setValid(()=>VALID);
                })
                .catch(error => {
                    console.log("error = " + error);
                    setValid(()=>INVALID);
                    onLogout();
                }).finally(() => {

                }
            );
        }
    }, [valid, setValid, validRef, cookies, onLogout, setSuratProgressList, murajaahExpiryDays, setMurajaahExpiryDays]);

    if (valid === INIT) {
        console.log("checking authorization while getting data...");
        return (
            <div>
                <Nav/>
                <CircularProgress color="secondary" />
            </div>
        );
    }
    else  if (valid === INVALID) {
        // wait until token is cleared in the cookies before redirecting to login page again
        if (isAvailable(cookies.token)) {
            console.log("cookies is available");
            return (
                <Container>
                    <h1>Invalid token....</h1>
                </Container>
            );
        }
        else{
            console.log("cookies is not available");
            return (
                <Redirect to={PATH_LOGIN}/>
            );
        }
    }
    else if (valid === TIMEOUT) {
        return (
            <Redirect to={PATH_LOGIN}/>
        );
    }
    else if (!isAvailable(cookies.token)) { // even if valid, possible for the token to be not available
        return (
            <Redirect to={PATH_LOGIN}/>
        );
    }

    let RealDataTable;
    if(suratProgressList){

        let normalizedRows = [];
        let i = 0;
        suratProgressList.forEach(function (item) {
            let elapsedTimeStr = 0;
            if(item.lastMemorizedDate){
                let d = new Date();
                let elapsedTime = d.getTime()-item.lastMemorizedDate;
                elapsedTimeStr = daysAgo(elapsedTime);
            }

            let aRow = {
                id: i,
                itemId: item.id,
                suratId: item.surat.id,
                pageNumber: item.surat.pageNumber,
                englishName: item.surat.englishName,
                indonesianTranslation: item.surat.indonesianTranslation,
                memorizedStatus: item.memorizedStatus,
                lastMemorizedDate: elapsedTimeStr,
                updated: false
            };
            // console.log("aRow=");
            // console.log(aRow);
            normalizedRows.push(aRow);
            i = i+1;
        });
        // console.log("normalizedRows=");
        // console.log(normalizedRows);

        RealDataTable = <DataTable rows={normalizedRows} email={cookies.email} token={cookies.token} murajaahExpiryDays={murajaahExpiryDays}/>;
    }
    else{
        RealDataTable = <p>loading...</p>;
    }
    return (
        <Container>
            <Nav/>
            <Me/>
                {RealDataTable}
        </Container>
    );

    function daysAgo(time){
        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour*24;

        let days = Math.floor(time/day);
        return days;
    }
}
