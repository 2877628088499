import * as React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {makeStyles} from "@material-ui/core";
import {useState} from "react";
import {useEffect} from "react";
import axios from "axios";
import {
    ColorButtonPurple,
    ColorButtonRed,
    ColorButtonAmber,
    ColorButtonBlue,
    ColorButtonGreen
} from "../buttons/CustomButtons";
import {useInterval} from "../../utils/Utils";
import {useRef} from "react";
import {useCheckTokenService} from "../../hooks/CheckTokenService";
import {DEFAULT_MURAJAAH_EXPIRY_DAYS} from "../settings/Settings";
import Container from "@material-ui/core/Container/Container";
import Link from "@material-ui/core/Link/Link";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles((theme) =>
    ({
        root: {
            '& .MuiDataGrid-row.Mui-even:not(:hover)': {
                backgroundColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
            },
        },
    }),
);

export default function DataTable({rows, email, token, murajaahExpiryDays}) {
    const classes = useStyles();
    const [memorizedMap, setMemorizedMap] = useState(null);
    const [inputRows, setInputRows] = useState(rows);

    // eslint-disable-next-line
    const [murajaahExpiryThresholdDays, setMurajaahExpiryThresholdDays] = useState(murajaahExpiryDays);
    // eslint-disable-next-line
    const [valid, setValid, validRef, cookies, onLogout, checkTimeout] = useCheckTokenService();

    function findNextMemorizedStatusId(id) {
        let newId = id - 1;
        if (newId === 0) {
            newId = 5;
        }
        return newId;
    }

    function findNextMemorizedStatus(params) {
        let touched = params.row.touched;
        // console.log('touched in findNextMemorizedStatus:');
        // console.log(touched);

        let memorizedStatus = params.value;
        let nextId = touched || (memorizedStatus.id === 5 && !needMurojaah(params)) ? findNextMemorizedStatusId(memorizedStatus.id) : 5;
        // console.log('nextId: ');
        // console.log(nextId);

        if (!memorizedMap || !memorizedMap.get(nextId)) {
            return memorizedStatus;
        }
        return memorizedMap.get(nextId);
    }

    function onClick(params) {
        // console.log('from onClick yeay!');
        // console.log(params);
        // console.log('memorizedStatus=');
        // console.log(params.value);

        let newMemStatus = findNextMemorizedStatus(params);
        // console.log('newMemStatus=');
        // console.log(newMemStatus);
        let rowId = params.row.id;
        // console.log('inputRows[rowId].memorizedStatus=');
        // console.log(inputRows[rowId].memorizedStatus);
        inputRows[rowId].memorizedStatus = newMemStatus;
        inputRows[rowId].updated = true;
        inputRows[rowId].touched = true;
        setInputRows(() => inputRows);
        // params.row[rowId].memorizedStatus = newMemStatus;
    }

    function needMurojaah(params) {
        // console.log('needMurojaah updated ?:');
        // console.log(params.row.updated);
        let updated = params.row.updated;
        let lastMemorizedXDaysAgo = params.row.lastMemorizedDate;
        let murajaahExpiryThresholdDaysNormalized = murajaahExpiryThresholdDays? murajaahExpiryThresholdDays: DEFAULT_MURAJAAH_EXPIRY_DAYS;
        // console.log('murajaahExpiryThresholdDaysNormalized=');
        // console.log(murajaahExpiryThresholdDaysNormalized);
        if (!updated && lastMemorizedXDaysAgo >= murajaahExpiryThresholdDaysNormalized) {
            return true;
        }
        return false;
    }

    function renderSurahLink(params){
        // console.log("params:");
        // console.log(params);
        return (
            <Typography variant="body2" color="textPrimary" align="center">
                <Link href={`${process.env.REACT_APP_MUSHAF_URL}?page=`+params.row.pageNumber} target="_blank" rel="noreferrer">{params.value}</Link>
            </Typography>
        );
    }

    function renderMemorizeStatusButton(params) {

        if (memorizedMap) {
            // console.log('params.value.id=');
            // console.log(params.value.id);
            memorizedMap.get(params.value.id);
        }

        if (params.value.id === 1) {
            return (
                <ColorButtonPurple
                    variant="contained"
                    color='primary'
                    size="small"
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={() => {
                        onClick(params);
                    }}
                >
                    {params.value.name}
                </ColorButtonPurple>
            );
        }
        else if (params.value.id === 2) {
            return (
                <ColorButtonRed
                    variant="contained"
                    color='primary'
                    size="small"
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={() => {
                        onClick(params);

                    }}
                >
                    {params.value.name}
                </ColorButtonRed>
            );
        }
        else if (params.value.id === 3) {
            return (
                <ColorButtonAmber
                    variant="contained"
                    color='primary'
                    size="small"
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={() => {
                        onClick(params);

                    }}
                >
                    {params.value.name}
                </ColorButtonAmber>
            );
        }
        else if (params.value.id === 4 || needMurojaah(params)) {
            let buttonStr = (params.value.id === 4) ?
                params.value.name : 'Need murojaah';
            return (
                <ColorButtonBlue
                    variant="contained"
                    color='primary'
                    size="small"
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={() => {
                        onClick(params);

                    }}
                >
                    {buttonStr}
                </ColorButtonBlue>
            );
        }
        else {
            return (
                <ColorButtonGreen
                    variant="contained"
                    color='primary'
                    size="small"
                    style={{
                        marginLeft: 10,
                    }}
                    onClick={() => {
                        onClick(params);
                    }}
                >
                    {params.value.name}
                </ColorButtonGreen>
            );
        }

    }

    const columns = [
        {field: 'id', headerName: 'Row Id', width: 130, hide: true},
        {field: 'itemId', headerName: 'Item Id', width: 130, hide: true},
        {field: 'suratId', headerName: 'ID', width: 50},
        // {field: 'englishName', headerName: 'English Name', width: 180},
        {field: 'englishName',
            headerName: "English Name",
            sortable: false,
            width: 150,
            disableClickEventBubbling: false,
            renderCell: renderSurahLink,},
        {field: 'pageNumber', headerName: 'Page Number', width: 100, hide: true},
        {field: 'indonesianTranslation', headerName: 'Translation', width: 250, hide: true},
        {
            field: 'lastMemorizedDate', headerName: 'Last Memorized', width: 180, hide: true,
            valueFormatter: (params) => {
                const valueFormatted = params.value;
                if (valueFormatted) {
                    return `${valueFormatted} day(s) ago`;
                }
                return "";

            },
        },
        {field: 'updated', headerName: 'Updated', width: 10, hide: true},
        {field: 'touched', headerName: 'Touched', width: 10, hide: true},
        {
            field: 'memorizedStatus',
            headerName: "Memorized",
            sortable: false,
            width: 230,
            disableClickEventBubbling: false,
            renderCell: renderMemorizeStatusButton,
        },
    ];

    const sendingRef = useRef(false);

    function sendUpdatedChangesToServer(currentRows) {
        // console.log('attempting to sendUpdatedChangesToServer..');
        let updatedRows = [];
        currentRows.forEach(function (item) {
            if (item.updated) {
                let updatedRow = {
                    rowId: item.id,
                    suratProgressId: item.itemId,
                    memorizedStatusId: item.memorizedStatus.id
                };
                updatedRows.push(updatedRow);
            }
        });

        if(updatedRows.length===0){
            return;
        }

        if(sendingRef.current){
            return;
        }
        else{
            sendingRef.current = true;
        }
        console.log('sending to server..');
        // send updatedRows to server
        axios.post(`${process.env.REACT_APP_SPRING_BOOT_URL}/surah-progress/updateSuratProgressList`, {
                email: email,
                dtoList: updatedRows},
                {
                headers: {
                    'Authorization': 'BEARER ' + token,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                const confirmedUpdatedRows = res.data.dtoList;
                console.log('confirmedUpdatedRows= ');
                console.log(confirmedUpdatedRows);
                confirmedUpdatedRows.forEach(function(item){
                    if (item.memorizedStatusId === inputRows[item.rowId].memorizedStatus.id){
                        inputRows[item.rowId].updated = false;
                        inputRows[item.rowId].lastMemorizedDate = 0;
                    }
                });
                setInputRows(()=> inputRows);
                console.log('successful!');
            })
            .catch(error => {
                console.log("error = " + error);
                onLogout();
            }).finally(() => {
                sendingRef.current = false;
            }
        );

        console.log('updatedRows:');
        console.log(updatedRows);
    }

    useInterval(() => {
        sendUpdatedChangesToServer(inputRows);
        // console.log('hola!');
    }, 3000);


    useEffect(() => {
        function convertToMap(memorizedList) {
            // console.log('memorizedList=');
            // console.log(memorizedList);

            const map = new Map();
            let i = 0;
            memorizedList.forEach(function (item) {
                let value = {
                    id: item.id,
                    name: item.name,
                };
                map.set(item.id, value);
                i = i + 1;
                // console.log("i=" + i);
            });
            // console.log('map=');
            // console.log(map);

            return map;
        }

        if (memorizedMap) {
            return;
        }
        axios.post(`${process.env.REACT_APP_SPRING_BOOT_URL}/memorized-status/searchMemorizedStatusList`, email, {
            headers: {
                'Authorization': 'BEARER ' + token,
                'Content-Type': 'text/plain',
            },
            data: {
                email,
            },
        })
            .then(res => {
                const memorizedList = res.data;
                setMemorizedMap(() => convertToMap(memorizedList));
            })
            .catch(error => {
                console.log("error = " + error);
                onLogout();
            }).finally(() => {

            }
        );
    }, [memorizedMap, setMemorizedMap, email, token, onLogout]);

    // find totalWidth for the parent Container based on the columns definitions
    let totalWidth = 0;
    columns.forEach(col => {
        if (!col.hide) {
            totalWidth += col.width;
        }
    });

    if (inputRows) {
        return (
            <Container style={{height: '90%', width: totalWidth}} className={classes.root}  maxWidth="sm">
                <DataGrid
                    style={{height: '100%', width: '100%', padding: 0, margin: 0}}
                    rows={inputRows} columns={columns}
                          sortModel={[
                              {
                                  field: 'suratId',
                                  sort: 'desc',
                              },
                          ]}
                          rowsPerPageOptions={[100, 200]}
                          autoHeight={true}
                          autoPageSize={true}
                    disableExtendRowFullWidth={true}
                />

            </Container>
        );
    }
    else {
        return (<Container><p>No data pal!</p></Container>)
    }

}
