import {useCallback, useRef, useState} from "react";
import {useCookies} from "react-cookie";
import {COOKIE_EMAIL, COOKIE_TOKEN} from "../components/login/Login";

export const INIT = "init";
export const VALID = "valid";
export const INVALID = "invalid";
export const TIMEOUT = "timeout";


export const useCheckTokenService = () =>{

    const [valid, setValid] = useState(INIT);
    const [cookies, removeCookie] = useCookies([COOKIE_EMAIL, COOKIE_TOKEN]);

    const removeCookieCallback = useCallback(()=>{
        removeCookie(COOKIE_TOKEN);
    }, [removeCookie]);

    const onLogout = useCallback(() =>{
        console.log('logging out');
        removeCookieCallback();
        setValid(()=>INVALID);
    },[removeCookieCallback, setValid]);

    const validRef = useRef(valid);

    const checkTimeout = (validRef, setValid, onLogout, timeout) =>{
        if(validRef.current !== INIT){
            return;
        }
        setTimeout(() => {
            if(validRef.current === INIT){
                setValid(()=> TIMEOUT);
                onLogout();
            }
        }, timeout);
    };

    return [valid, setValid, validRef, cookies, onLogout, checkTimeout];
};
