import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import {useCheckTokenService} from "../../hooks/CheckTokenService";
import {PATH_DASHBOARD, PATH_SETTINGS, PATH_CONTACTS} from "../../App";
import Container from "@material-ui/core/Container/Container";

const useStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function Nav() {
    const classes = useStyles();
    // eslint-disable-next-line
    const [valid, setValid, validRef, cookies, onLogout, checkTimeout] = useCheckTokenService();

    return (
        <Container className={classes.root} maxWidth='sm'>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <Button href={PATH_DASHBOARD}>Dashboard</Button>
                <Button href={PATH_SETTINGS}>Settings</Button>
                <Button href={PATH_CONTACTS}>Contacts</Button>
                <Button color="secondary" onClick={onLogout}>Logout</Button>
            </ButtonGroup>
        </Container>
    );
}
