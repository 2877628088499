import {useCheckTokenService} from "../../hooks/CheckTokenService";
import {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";

export default function Me(props){
    const [message, setMessage] = useState();
    // eslint-disable-next-line
    const [valid, setValid, validRef, cookies, onLogout, checkTimeout] = useCheckTokenService();

    useEffect(()=> {
        let email = cookies.email;
        setMessage(()=>email);
    },[cookies]);

    return(
        <Typography variant="overline" color="textSecondary" align="center">{message}</Typography>
    )
}
