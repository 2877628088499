import React, {useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {PATH_LOGIN} from "../../App";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function MailtoLink({email, mailto}){
    let mailtoHref = 'mailto:'+email;
    if(mailto){
        return(<Link href={mailtoHref}>{email}</Link>);
    }
    else{
        return(<Link>{email}</Link>);
    }

}

export default function Register() {
    const classes = useStyles();
    const [successful, setSuccessful] = useState();
    const [message, setMessage] = useState();
    const [signingUp, setSigningUp] = useState();
    const [email, setEmail] = useState();

    let emailRef = useRef();
    let passwordRef = useRef();

    const onSubmit = (e) => {
        e.preventDefault();
        setMessage(() => "");
        setSigningUp(() => true);

        let username = emailRef.current.value;
        let password = passwordRef.current.value;

        axios.post(`${process.env.REACT_APP_SPRING_BOOT_URL}/auth/register`, {
            email: username,
            password: password,
        })
            .then(res => {
                console.log('res.data:');
                console.log(res.data);
                const success = res.data.successful;

                if(success){
                    setSuccessful(()=>true);
                    setEmail(()=> res.data.email);
                    setMessage(()=>'Successfully sent verification email to : '+res.data.email +' . Please check your email inbox and click the verification link we have provided. ' +
                        'If you encountered technical issues, please email to hafalquran.azifu@gmail.com');
                }
                else{ // error
                    setSuccessful(()=>false);
                    setMessage(()=>res.data.errorMsg);
                }
            })
            .catch(error => {
                setSuccessful(()=> false);
                console.log("error = " + error);
                let errorMsg = error;
                if (error.response) {
                    console.log(error.response.data);
                    if(error.response.data.error){
                        errorMsg = error.response.data.error;
                    }
                    if(error.response.data.message){
                        errorMsg = error.response.data.message;
                    }
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
                else if(error.message){
                    errorMsg = error.message;
                }
                setMessage(() => 'Unable to register. ' + errorMsg);
            }).finally(() => {
                setSigningUp(() => false);
            }
        );
    };


    let Spinner = signingUp? <CircularProgress color="secondary" />: <div/>;
    let disableButton = !!successful;


    let FailedMessageContentComp = <>{message}</>;
    let SuccessfulMessageContentComp = <>Successfully sent verification email to : <MailtoLink email={email} mailto={false}/>. Please check your email inbox and click the verification link we have provided. If you encountered technical issues, please email to <MailtoLink email='hafalquran.azifu@gmail.com' mailto={true}/></>;

    let MessageContentComp = successful?SuccessfulMessageContentComp: FailedMessageContentComp;

    let messageColor = successful?"textPrimary":"error";
    let MessageComponent = <Typography variant="body2" color={messageColor} align="center">{MessageContentComp}</Typography>;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputRef={emailRef}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                inputRef={passwordRef}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {Spinner}
                            {MessageComponent}
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onSubmit}
                        disable={disableButton.toString()}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href={PATH_LOGIN} variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
