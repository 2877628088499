import Nav from "../nav/Nav";
import React, {useEffect, useState} from "react";
import {INVALID, TIMEOUT, useCheckTokenService} from "../../hooks/CheckTokenService";
import {isAvailable} from "../../utils/Utils";
import {PATH_LOGIN} from "../../App";
import {Redirect} from "react-router";
import SettingMurajaahExpiry from "./SettingMurajaahExpiry";
import './Settings.css'
import axios from "axios";
import Me from "../nav/Me";

export const DEFAULT_MURAJAAH_EXPIRY_DAYS = "5";

export default function Settings(props){
    // eslint-disable-next-line
    const [valid, setValid, validRef, cookies, onLogout, checkTimeout] = useCheckTokenService();
    const [initSettings, setInitSettings] = useState({
        retrieved: false,
        murajaahExpiryThresholdDays: DEFAULT_MURAJAAH_EXPIRY_DAYS
    });

    useEffect(()=>{
        if(initSettings.retrieved){
           return;
        }
        let email = cookies.email;
        let token = cookies.token;
        axios.post(`${process.env.REACT_APP_SPRING_BOOT_URL}/user/searchUserByEmail`, email, {
            headers: {
                'Authorization': 'BEARER ' + token,
                'Content-Type': 'text/plain',
            },
        })
            .then(res => {
                const data = res.data;
                console.log('data:');
                console.log(data);
                let retrievedSettings = {
                    retrieved: true,
                    murajaahExpiryDays: data.murajaahExpiryThresholdDays
                };
                setInitSettings(()=> retrievedSettings);
            })
            .catch(error => {
                onLogout();
            }).finally(() => {

            }
        );
    },[cookies, onLogout, initSettings]);

    // console.log('initSettings:');
    // console.log(initSettings);
    let myInitVal = initSettings && initSettings.murajaahExpiryDays?
        initSettings.murajaahExpiryDays: DEFAULT_MURAJAAH_EXPIRY_DAYS;
    let RealMurajaahExpiry = <SettingMurajaahExpiry initVal={myInitVal} email={cookies.email} token={cookies.token}/>;

    if (valid === INVALID) {
        // wait until token is cleared in the cookies before redirecting to login page again
        if (isAvailable(cookies.token)) {
            return (
                <div>
                    <h1>Invalid token....</h1>
                </div>
            );
        }
        else{
            return (
                <Redirect to={PATH_LOGIN}/>
            );
        }
    }
    else if (valid === TIMEOUT) {
        return (
            <Redirect to={PATH_LOGIN}/>
        );
    }
    else if (!isAvailable(cookies.token)) { // even if valid, possible for the token to be not available
        return (
            <Redirect to={PATH_LOGIN}/>
        );
    }

    return (
        <div>
            <Nav/>
            <Me/>
            <div className='centered'>
                {RealMurajaahExpiry}
            </div>
        </div>
    );
}
