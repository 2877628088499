import {useRef, useState} from "react";
import axios from 'axios';
import {Redirect, useLocation} from "react-router-dom";
import {PATH_DASHBOARD, PATH_REGISTER} from "../../App";
import {useCookies} from "react-cookie";
import React from "react";
import {isAvailable} from "../../utils/Utils";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Avatar from "@material-ui/core/Avatar/Avatar";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import Link from "@material-ui/core/Link/Link";
import Container from "@material-ui/core/Container/Container";
import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export const COOKIE_EMAIL = "email";
export const COOKIE_TOKEN = "token";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login(props) {
    const classes = useStyles();

    const [loggingIn, setLoggingIn] = useState(false);
    const [message, setMessage] = useState();
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_EMAIL, COOKIE_TOKEN]);

    const search = useLocation().search;
    const emailInRequestParam = new URLSearchParams(search).get("email");

    const ATTR_DISABLED = "disabled";

    let emailRef = useRef();
    let passwordRef = useRef();
    let submitBtnRef = useRef();

    const onSubmit = (e) => {
        e.preventDefault();
        if (submitBtnRef.current && submitBtnRef.current.getAttribute(ATTR_DISABLED) === true) {
            return;
        }
        submitBtnRef.current.setAttribute(ATTR_DISABLED, true);
        setMessage(() => "");
        setLoggingIn(() => true);

        removeCookie(COOKIE_EMAIL);
        removeCookie(COOKIE_TOKEN);

        let username = emailRef.current.value;
        let password = passwordRef.current.value;

        // console.log('username:');
        // console.log(username);
        // console.log('password:');
        // console.log(password);

        const delimiter = "---";
        var body = username+delimiter+password;

        axios.post(`${process.env.REACT_APP_SPRING_BOOT_URL}/auth/accessToken`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                const token = res.data;
                console.log("token = " + token);
                setCookie(COOKIE_EMAIL, username, {path: '/'});
                setCookie(COOKIE_TOKEN, token, {path: '/'});
            })
            .catch(error => {
                console.log("error = " + error);
                let errorMsg = error;
                if (error.response) {
                    console.log(error.response.data);
                    if (error.response.data.error) {
                        errorMsg = error.response.data.error;
                    }
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
                removeCookie(COOKIE_EMAIL);
                removeCookie(COOKIE_TOKEN);
                setMessage(() => 'Unable to login. ' + errorMsg);
                setLoggingIn(() => false);
            }).finally(() => {
                if (submitBtnRef && submitBtnRef.current) {
                    submitBtnRef.current.removeAttribute(ATTR_DISABLED);
                }
            }
        );
    };

    if (isAvailable(cookies.token)) {
        console.log("redirecting to dashboard");
        return (<Redirect to={PATH_DASHBOARD}/>);
    }

    let spinner = loggingIn ?
        <CircularProgress color="secondary"/> :
        <div></div>;

    return (
        <div>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            inputRef={emailRef}
                            placeholder="Email address"
                            defaultValue={emailInRequestParam ? emailInRequestParam : null}
                            // value={username}
                            // onChange={onUsernameChanged}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            inputRef={passwordRef}
                            placeholder="Password"
                            // value={password}
                            // onChange={onPasswordChanged}
                        />
                        <Grid container>
                            <Grid item xs>
                                {spinner}
                                <Typography variant="body2" color="error">
                                    {message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            ref={submitBtnRef} onClick={onSubmit}>
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href={PATH_REGISTER} variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </div>
    );
};
