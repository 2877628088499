import React from 'react';
import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Settings from './components/settings/Settings';
import Copyright from "./components/footer/Copyright";
import Box from "@material-ui/core/Box/Box";
import MyContacts from './components/contacts/MyContacts';
import ConfirmEmail from './components/passwordless/ConfirmEmail';
import Register from './components/login/Register';


export const PATH_LOGIN = "/login";
export const PATH_DASHBOARD = "/dashboard";
export const PATH_SETTINGS = "/settings";
export const PATH_CONTACTS = "/contacts";
export const PATH_REGISTER ="/register";

function App() {

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path={PATH_LOGIN}>
                        <Login/>
                    </Route>
                    <Route path={PATH_DASHBOARD}>
                        <Dashboard/>
                    </Route>
                    <Route path={PATH_SETTINGS}>
                        <Settings/>
                    </Route>
                    <Route path={PATH_CONTACTS}>
                        <MyContacts />
                    </Route>
                    <Route path={'/confirm-email'}>
                        <ConfirmEmail />
                    </Route>
                    <Route path={PATH_REGISTER}>
                        <Register />
                    </Route>
                    <Redirect to={PATH_LOGIN}/>
                </Switch>
            </BrowserRouter>
            <Box mt={8}>
                <Copyright />
            </Box>
        </div>
    );
}

export default App;
