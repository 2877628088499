import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import React, {useEffect, useRef, useState} from "react";
import {useInterval} from "../../utils/Utils";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export default function SettingMurajaahExpiry({initVal, email, token}){
    // console.log('initVal=');
    // console.log(initVal);
    const [murajaahExpiryDays, setMurajaahExpiryDays] = useState({
        updated: false,
        value: initVal
    });

    useEffect(()=>{
        if(!initVal){
            return;
        }
        let newObj = {
            updated: false,
            value: initVal
        };
        setMurajaahExpiryDays(()=> newObj);
    },[initVal]);

    const onMurajaahExpiryDaysChangeStub = (newValue) =>{
        // console.log("newValue=");
        // console.log(newValue);
        if(!newValue){
            return;
        }
        // console.log('setting murajaah expiry days to: '+ newValue);
        let newObj = {
            updated: true,
            value: newValue
        };
        setMurajaahExpiryDays(()=> newObj);
    };

    const onAutoCompleteMurajaahExpiryDaysChange= (e,v) =>{

        onMurajaahExpiryDaysChangeStub(v);
    };

    const onTextFieldMurajaahExpiryDaysChange= (e,v) =>{
        let enterKey = 13; //Key Code for Enter Key
        if (e.keyCode === enterKey){
            onMurajaahExpiryDaysChangeStub(e.target.value);
        }
    };

    // console.log('murajaahExpiryDays.value');
    // console.log(murajaahExpiryDays.value);

    const sendingRef = useRef(false);

    function sendUpdatedChangesToServer(murajaahExpiryDays) {
        console.log('attempting to sendUpdatedChangesToServer..');
        if(murajaahExpiryDays.value && murajaahExpiryDays.updated){
            console.log('murajaahExpiryDays is updated');
        }
        else{
            return;
        }
        // let userSetting = {
        //     email: email,
        //     murajaahExpiryThresholdDays: murajaahExpiryDays.value,
        // };

        if(sendingRef.current){
            return;
        }
        else{
            sendingRef.current = true;
        }
        console.log('sending to server..');
        // send updatedRows to server
        axios.post(`${process.env.REACT_APP_SPRING_BOOT_URL}/user/updateUserSettings`, {
                email: email,
                murajaahExpiryThresholdDays: murajaahExpiryDays.value,
            },
            {
                headers: {
                    'Authorization': 'BEARER ' + token,
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                const confirmedUserDto = res.data;
                console.log('confirmedUserDto= ');
                console.log(confirmedUserDto);
                if(confirmedUserDto.murajaahExpiryThresholdDays.toString() === murajaahExpiryDays.value.toString()){
                    console.log('yes the same');
                    let newObj = {
                        updated: false,
                        value: parseInt(murajaahExpiryDays.value)
                    };
                    setMurajaahExpiryDays(()=> newObj);
                }
                else{
                    console.log('no. different');
                    console.log(confirmedUserDto.murajaahExpiryThresholdDays);
                    console.log('vs');
                    console.log(murajaahExpiryDays.value);
                }
                console.log('successful!');
            })
            .catch(error => {
                console.log("error = " + error);
                // dont care abt error first
                // onLogout();
            }).finally(() => {
                sendingRef.current = false;
            }
        );
        //
        // console.log('updatedRows:');
        // console.log(updatedRows);
    }

    useInterval(() => {
        sendUpdatedChangesToServer(murajaahExpiryDays);
        // console.log('hola!');
    }, 3000);


    let AutoCompleteComponent =  <Autocomplete
        id="murajaah-expiry-days"
        freeSolo
        options={entries.map((option) => option.value)}
        value={murajaahExpiryDays.value.toString()} // need a string value instead of number
        onChange={onAutoCompleteMurajaahExpiryDaysChange}
        renderInput={(params) => (
            <TextField {...params}
                       label="Murajaah Expired (in Days)"
                       margin="normal"
                       variant="outlined"
                       placeholder="Murajaah Expired (in Days)"
                       onKeyDown={onTextFieldMurajaahExpiryDaysChange}
            />
        )}
    />;

    console.log('murajaahExpiryDays:');
    console.log(murajaahExpiryDays);
    if(murajaahExpiryDays && murajaahExpiryDays.updated){
        console.log('yes with spinner');
        return(
            <div style={{width: 290, display: 'flex'}}>
                <div style={{width: 230,}}>
                    {AutoCompleteComponent}
                </div>
                <div style={{paddingTop: 20, paddingLeft: 10}}>
                    <CircularProgress color="secondary" />
                </div>
                {/*<h1>tseting</h1>*/}
            </div>
        )
    }
    console.log('no without spinner');
    return(
        <div style={{ width: 230 }}>
            {AutoCompleteComponent}
            {/*<CircularProgress color="secondary" />*/}
            {/*<h1>tseting</h1>*/}
        </div>
    );
}

const entries = [
    { value: "3" },
    { value: "7" },
    { value: "10" },
];
